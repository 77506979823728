#audio-player-container {
  z-index: 1000;
}
.timeline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 373.33px;
  height: 188px;
  flex: none;
  order: 0;
  flex-grow: 1;
}
.timeline > button:first-of-type {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 72px;
  height: 72px;
  flex: none;

  background: radial-gradient(50% 98.88% at 50% 50%, #474747 0%, #3c3c3c 100%);
  border: 2px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.75);
  border-radius: 40px;
}
.timeline .rawBtn:first-of-type {
  padding: 2px 32px;
  gap: 8px;
  width: 150px;

  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.timeline .buy-btn {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  background: #d6f119;
  background-position: 1rem 1rem;
  border: 2px solid rgba(0, 0, 0, 0.4);
  padding: 0 2rem;
  height: 36px;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
.timeline .buy-btn:hover {
  background: #aec514;
}
.timeline img {
  width: 32px;
  height: 32px;
  flex: none;
}
.timelineBtnContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  gap: 12px;
  text-align: center;
  color: #ffffff;
  opacity: 0.25;
  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.timelineTime {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.timelineDash {
  box-sizing: border-box;
  width: 4px;
  height: 36px;
  background: linear-gradient(180deg, #0e0e0e 0%, #3d3d3d 100%);
  box-shadow: inset -2px 0px 2px rgba(255, 255, 255, 0.05),
    inset 2px 0px 2px rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
}
.hoverData {
  color: white;
  position: absolute;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  padding: 10px 20px;
  border-radius: 20px;
}
.horizontal-slider {
  width: 100%;
  max-width: 500px;
  height: 100vh;
  margin: auto;
}
.timeline > .mobileBtn {
  display: none;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"].slider-1::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #cefa00;
  border: 8px solid #d3d3d3;
  box-shadow: 0 0 2px black;
}
.slider-1::-webkit-slider-runnable-track {
  background: #d6f119;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.slider-2::-webkit-slider-runnable-track {
  background: #19e4f1;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}
.slider-3::-webkit-slider-runnable-track {
  background: #f1194d;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
}

@media screen and (max-width: 1400px) {
  .timeline {
    width: 230px;
    display: flex;
    justify-content: center;
    margin: auto;
  }
}
@media screen and (max-width: 550px) {
  .timeline {
    width: 90%;
  }
  .timeline .timelineTime {
    display: none;
  }
  .buy-btn-link {
    display: none;
  }
  .timeline > .mobileBtn {
    display: flex !important;
    text-decoration: none !important;
  }
  .pedalTimelineWhole {
    display: flex;
    column-count: 2;
    flex-direction: row;
  }
  .pedalTimelineBtn {
    display: flex;
  }
  .pedalTimeline {
    display: flex;
  }
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: white !important;
  border: 10px solid #404040;
  border-radius: 100%;
  display: block;
  box-shadow: -3px 5px 12px rgba(0, 0, 0, 1);
}

.example-track {
  position: relative;
  background: #888;
}

.example-track.example-track-0 {
  background: white;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 8px;
  border-radius: 20px;
}

.horizontal-slider .example-thumb {
  top: 8px;
  width: 12px;
  outline: none;
  height: 12px;
  line-height: 38px;
}
