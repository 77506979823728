.signin {
  z-index: 100;
  width: 100%;
  min-height: calc(100dvh - 64px) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
}
.signin input {
  width: 60%;
  border: none;
  padding: 1% 2%;
  height: 100%;
  border-radius: 45px;
}
.signin input[type="email"] {
  height: 40px;
  font-size: 20px;
}
.signin input::placeholder {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0;
}
.signin button {
  padding: 8px 32px;
  gap: 4px;
  width: 140px;
  height: 52px;
  background: #d6f119;
  border-radius: 48px;
  border: none;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.45);
  margin-top: 15px;
}
.signin button > p {
  color: #1a1a1a;
  font-size: 20px;
  margin: 0%;
}
.signin button:hover {
  background-color: #d4f119b9;
  cursor: pointer;
}

.signin input:focus {
  outline: none;
}

.signinBtnDiv {
  display: flex;
  align-items: center;
  gap: 15px;
}

.signinBtn {
  padding: 8px 32px;
  gap: 4px;
  height: 52px;
  background: #d6f119;
  border-radius: 48px;
  border: none;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.45);
  margin-bottom: 20px;
}
.signinBtn > p {
  color: #1a1a1a;
  font-size: 20px;
  margin: 0%;
}
.signinBtn:hover {
  background-color: #d4f119b9;
  cursor: pointer;
}
.signinBtn.outline {
  background: transparent;
  border: #d6f119 3px solid;
}
.signinBtn.outline > p {
  color: white;
  font-size: 20px;
  margin: 0%;
}
.signinBtn.outline:hover {
  background: #ffffff25;
  border: #d6f119 3px solid;
}

.signinSplash {
  width: auto;
  max-width: 30vw;
  max-height: 50vh;
  margin-bottom: 15px;
  margin-top: 15px;
}

.background-image-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-image: url("../../images/Splash_Background.png");
  background-size: contain;
}

@media screen and (max-width: 1200px) {
  .signinSplash {
    font-size: 4rem;
  }
}

@media screen and (max-width: 1024px) {
  .signin input {
    width: 70%;
    padding: 1% 3%;
  }
}

@media screen and (max-width: 768px) {
  .signinSplash {
    font-size: 3rem;
    max-width: 60vw;
  }
}
