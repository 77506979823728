.rigselection {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: auto;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  margin: 9% 0%;
  padding: 5% 0%;
  position: relative;
}
.rigInfo {
  color: white;
  border: 1px solid white !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  text-decoration: none !important;
  position: absolute;
  right: -1.8rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1rem !important;
  box-sizing: border-box;
  padding: 0 !important;
}

.rigInfo:hover {
  background: #ffffff55;
}

.rigDetailsText {
  height: auto !important;
  opacity: 1 !important;
  text-transform: none !important;
  text-align: left !important;
  font-family: inherit !important;
  font-weight: normal !important;
}
.rigDetailsCross {
  height: 12px !important;
}

.rigselection button:hover {
  cursor: pointer;
}
.rigselection p {
  height: 20px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  text-transform: uppercase;
  margin: 0 auto;
}
.singleRig > button {
  width: 100%;
  padding: 0%;
  width: 85%;
}
.singleRig > button > p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto !important;
}
.singleRig > p {
  padding-top: 20px;
}
.singleRig > img {
  width: 160px !important;
  height: 120px !important;
  object-fit: contain;
}
.rigselection > button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 333.33px;
  height: auto;
  background: transparent;
  border: none;
  z-index: 100;
}
.clickableImages {
  width: 200px;
  height: 100px;
  object-fit: contain;
}
.rigselection > button > p {
  flex: none;
  order: 0;
  flex-grow: 0;
  opacity: 1;
}
.rigDetails {
  width: max-content !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  left: 50% !important;
}
@media screen and (max-width: 1400px) {
  .rigselection {
    width: 250px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .rigselection button {
    width: 230px;
  }
  .clickableImages {
    height: 70px;
  }
  .rigDetails {
    right: unset !important;
  }
}
@media screen and (max-width: 1024px) {
  .rigInfo {
    right: unset;
    top: 9.7rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .rigs {
    margin-bottom: 1.4rem;
  }
  .rigDetails {
    top: 11.5rem !important;
  }
}
@media screen and (max-width: 550px) {
  .rigselection {
    width: 100%;
    margin: 5px;
    padding: 1.6rem 0;
  }
  .rigselection:last-of-type {
    margin-top: 1.2rem;
  }
  .rigselection button {
    width: 150px;
  }
  .rigselection button p {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rigDetails {
    width: 100% !important;
  }
}
