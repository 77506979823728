.aboutPage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  min-height: calc(100dvh - 64px) !important;
  max-width: 90rem;
  padding: 0 1.5rem;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
}

.aboutPage a {
  color: white;
}

.categorySection h3 {
  text-decoration: underline;
}

.categorySectionGlobal > .categorySubType > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 1400px) {
  .aboutPage {
    width: 100%;
  }
  .header {
    margin: 20px;
  }
}
