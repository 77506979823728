.FilteringPage {
  z-index: 10;
  min-height: calc(100vh - 64px) !important;
  color: white;
}
.Info {
  text-align: right;
  margin: 10px;
  margin-top: 0px;
}
.searchField {
  margin: 10px 5px;
  padding: 10px 20px;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  border: 1px solid rgba(8, 8, 8, 0.548);
  color: white;
}
.Filtered__table__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
  font-weight: bold;
}

.Filtered__table {
  background-color: #1a1a1a;
  color: white;
  height: calc(100vh - 170px);
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: auto;
  padding-bottom: 10px;
}

.selectDropDown {
  margin: 10px 5px;
  padding: 10px 20px;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  border: 1px solid rgba(8, 8, 8, 0.548);
  color: white;
}
.selectDropDown:hover {
  cursor: pointer;
}
.selectDropDown:disabled {
  cursor: not-allowed;
}

table {
  width: 100%;
  border-collapse: collapse;
}
th {
  background-color: #151515;
  z-index: 10000;
  font-weight: bold;
  padding: 20px 10px !important;
}
td,
th {
  padding: 6px;
  border: 1px solid #42424266;
  text-align: left;
}
thead {
  position: sticky;
  top: 0;
  background-color: #1a1a1a;
}

.pedalImage {
  height: 70px;
  cursor: pointer;
}
.pedalNameContainer {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: start;
}
.pedalMoreInfo {
  font-size: 0.6rem;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .FilteringPage__search select {
    width: calc(50% - 10px);
    margin: 5px;
  }
  .searchField {
    width: calc(100% - 50px);
    margin: 5px;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border-bottom: 5px solid #42424266;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #42424266;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /* Style Image on Mobile */
  td:nth-of-type(1) {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
  }
  td:nth-of-type(1) > img {
    height: 150px;
  }

  /*
	Label the data
	*/
  td:nth-of-type(2):before {
    content: "Pedal Brand";
  }
  td:nth-of-type(3):before {
    content: "Phrase";
  }
  td:nth-of-type(4):before {
    content: "Guitar";
  }
  td:nth-of-type(5):before {
    content: "Amplifier";
  }
}
