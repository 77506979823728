.subscribePage {
    z-index: 10;
    height: calc(100vh - 64px) !important;
}
h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.subscribePage button {
    padding: 8px 32px;
    gap: 4px;
    width: 140px;
    height: 52px;
    background: #D6F119;
    border-radius: 48px;
    border: none;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.45);
    margin-top: 15px;
}
.subscribePage button > p {
    color: #1A1A1A;
    font-size: 20px;
    margin: 0%;
}
.subscribePage button:hover {
    background-color: #d4f119b9;
    cursor: pointer;
}

@media screen and (max-width: 1400px) {
    .subscribePage {
        height: 100vh;
        overflow-y: hidden !important;
    }
}