.phraseDiv {
  display: flex;
  margin-bottom: 20px;
  overflow: hidden;
  justify-content: center;
}
.phraseselection {
  z-index: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 442px;
  width: 373.33px;
  height: 60px;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0%;
}
.phraseselection:hover {
  cursor: pointer;
}
.phraseselection:disabled {
  cursor: not-allowed;
}
.phraseDiv p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.phraseModal,
.phraseModalPedal {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 30px;
  gap: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  max-height: 600px;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  z-index: 1000000;
  text-align: center;
  overflow-y: scroll;
}

.phraseModalPedal {
  width: 825px;
}

.phraseModal h3 {
  color: white;
  margin-bottom: 0;
}
.phraseModal::-webkit-scrollbar,
.phraseModalPedal::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.phraseModalPedal {
  display: flex;
  flex-direction: column;
}
.modalHeading {
  border-bottom: 1px solid rgba(255, 255, 255, 0.548);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 108%;
  background-color: #1a1a1a;
  z-index: 1000;
}
.modalHeading > .headingPTag {
  margin: 0;
  padding: 20px 0;
  margin-left: 50px;
  justify-content: center;
}
.modalHeading > .closeModal {
  margin: 0;
  margin-right: 50px;
  padding: 20px 0;
}
.phraseModal ul {
  list-style-type: none;
  color: white;
  padding: 0%;
  margin: 0%;
  text-align: center;
}
.phraseModal ul > li {
  padding: 5px 0;
}
.phraseModal ul > li:hover {
  color: #d6f119;
  cursor: pointer;
}
.mappedPedals {
  display: flex;
  flex-flow: row wrap;
  position: relative;
}
.singlePedal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 8px;
  margin: 15px;
  margin-top: 0px;
  gap: 8px;
  width: 200px;
  height: auto;
  object-fit: contain;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 1;
  transition: all ease-in-out 0.3s;
}
.closeModal:hover {
  cursor: pointer;
}
.singlePedal:hover {
  cursor: pointer;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 1);
}
.singlePedal > img {
  width: 200px !important;
  height: 180px !important;
  object-fit: contain;
}
.singlePedal > button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  width: 120px;
  height: 32px;
  background: rgba(214, 241, 25, 0.05);
  border: 1px solid #d6f119;
  border-radius: 48px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.singlePedal > button > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #d6f119;
  flex: none;
  order: 0;
  flex-grow: 0;
}
@media screen and (max-width: 1400px) {
  .phraseselection {
    width: 250px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .phraseselection button {
    width: 230px;
  }
}

@media screen and (max-width: 860px) {
  .phraseModalPedal {
    width: 550px;
  }
}
@media screen and (max-width: 550px) {
  .phraseDiv button {
    width: 90%;
  }
  .phraseModal {
    width: 100%;
    height: fit-content;
    background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
    box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
    border-radius: 12px;
  }
  .phraseModalPedal {
    width: 100%;
    height: 600px;
    background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
    box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
    border-radius: 12px;
  }
}
