.App {
  width: 100%;
  min-height: 100vh;
  background: radial-gradient(50% 98.88% at 50% 50%, #474747 0%, #1c1c1c 100%);
}
.protectedRotue {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  display: flex;
}
.bgImage {
  background: url("./images/background.png");
  background-size: auto;
  mix-blend-mode: overlay;
  opacity: 0.4;
  position: absolute;
  bottom: 0%;
  object-fit: fill;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.background {
  display: flex;
  height: 100%;
  flex-flow: column;
  margin: 0% auto;
  align-items: center;
}
@media screen and (max-width: 1100px) {
  .background {
    height: 100%;
  }
  .bgImage {
    height: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .background {
    height: 100vh;
  }
  .bgImage {
    height: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .background {
    height: 100%;
  }
  .bgImage {
    height: 100%;
  }
}
.modalBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 250vw;
  height: 250vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  pointer-events: none;
  cursor: not-allowed;
  backdrop-filter: blur(10px);
}

.tooltip-parent {
  position: relative;
}
.tooltip {
  position: absolute;
  top: 50%;
  left: 4rem;
  background-color: #000;
  z-index: 1000;
  width: 250px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  transition: all 0.3s ease;
}

.font-effect-ice {
  mask-image: url("./images/ice.png");
  -webkit-mask-image: url("./images/ice.png");
  color: #0cf;
}
