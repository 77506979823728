.container {
  width: 1440px;
}
.navbar {
  display: none;
}
.resetButton {
  position: relative;
  z-index: 1;
  padding: 5px 10px;
  width: 180px;
  height: 46px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border: none;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.45);
}
.resetButton > p {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.resetButton:hover {
  cursor: pointer;
}
.topheader {
  display: flex;
  column-count: 4;
  gap: 120px;
  padding: 0%;
}
.topheader div {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px auto;
  box-sizing: border-box;
}

@media screen and (max-width: 1400px) {
  .topheader div {
    padding-left: 5rem;
  }
}

@media screen and (max-width: 1280px) {
  .topheader {
    gap: 60px;
  }
  .topheader div {
    margin: 0;
    padding-left: 5rem;
    margin-top: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .topheader {
    display: flex;
    margin: 0;
    justify-content: center;
  }
  .topheader div {
    padding-left: 0;
    width: auto;
  }
}
.selectionPage {
  display: flex;
  column-count: 2;
  justify-content: space-between;
  padding: 0%;
  margin-top: 0px;
}
h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.pedalSelection {
  display: flex;
  column-count: 2;
  justify-content: space-between;
  gap: 180px;
  position: relative;
}
.timeline {
  bottom: 0%;
  display: flex;
}
.pedalDiv {
  justify-content: center;
  margin: 0 auto;
}
.pedalDiv > div {
  position: relative;
}
.pedalDivImg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 460px;
  object-fit: contain;
  border: none;
  outline: none;
  position: relative;
}
.pedalDivImg > div {
  position: relative;
}
.pedalDivImg .pedalLinksContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: absolute;
  right: -2rem;
  top: 40%;
  transform: translateY(-50%);
}
.pedalDivImg img {
  height: 440px;
  max-width: 370px;
  object-fit: contain;
}

@media screen and (max-width: 1024px) {
  .pedalDivImg {
    height: 320px;
  }
  .pedalDivImg img {
    height: 300px;
    max-width: 310px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1400px) {
  .pedalDivImg {
    height: 336px;
  }
  .pedalDivImg img {
    max-width: 300px;
    height: 320px;
  }
}
.pedalInfo {
  color: white;
  border: 1px solid white;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  text-decoration: none !important;
}
.pedalInfo:hover {
  background: #ffffff55;
}

.pedalDetails {
  position: absolute;
  color: #ffffff;
  background: linear-gradient(191.07deg, #1a1a1a 8.18%, #1a1a1a 91.82%);
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45);
  border-radius: 12px;
  padding: 10px 20px;
  max-width: 95%;
  height: auto;
  z-index: 10000;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  box-sizing: border-box;
  width: max-content;
}

.pedalDetails > .pedalName {
  margin-right: 50px;
}
.pedalDetails > p {
  margin: 0%;
}
.pedalDetailsCross {
  display: flex;
  justify-content: end;
  float: right;
  margin: 0;
}
.pedalDetailsCross > p {
  display: flex;
  border: 1px solid white;
  padding: 7px;
  width: 12px;
  height: 12px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
  margin: 0%;
}
.pedalDetailsCross:hover,
.pedalInfo:hover {
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .container {
    width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .container {
    width: 100%;
  }
  .rigPage {
    width: 100%;
  }
  .selectionPage {
    width: 100%;
  }
  .pedalSelection {
    gap: 0px;
  }
  .rigSelection {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .pedalSelection {
    width: 70%;
    display: flex;
    flex-direction: row;
  }
  .pedalDiv {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 880px) {
  .rigPage {
    height: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .selectionPage {
    display: flex;
    flex-direction: column;
  }
  .rigPage {
    height: 100%;
  }
  .resetButton {
    float: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pedalInfo {
    margin-left: 15px;
  }
  .rigSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    width: 100%;
  }
  .rigs {
    display: flex;
    gap: 20px;
    flex-direction: row;
  }
  .pedalSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
  .pedalDiv {
    margin: 30px 0px;
  }
  .selectPhrase {
    width: 100%;
  }
  .phraseModal {
    width: 550px;
  }

  .pedalDetails {
    top: 50%;
  }
}

@media screen and (max-width: 520px) {
  .rigPage {
    height: 100%;
  }
}
@media screen and (max-width: 550px) {
  .selectionPage {
    display: flex;
    flex-direction: column;
  }
  .rigSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 0;
    width: 100%;
  }
  .rigSelection > div {
    width: 90%;
  }
  .rigs {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .pedalSelection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
  .pedalDiv {
    margin: 30px 0px;
  }
  .selectPhrase {
    width: 100%;
  }
  .phraseModal {
    width: 100%;
  }

  .pedalDetails {
    max-width: calc(100vw - 4rem);
    width: 100%;
  }

  .pedalName {
    overflow-wrap: anywhere;
  }
}

.ctc * {
  box-sizing: border-box;
}

.ctc .rigSelection > div {
  width: unset;
}

.ctc .rigInfo {
  display: none;
}

.ctc .rigselection {
  justify-content: start;
  width: unset;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 451px) {
  .ctc .rigselection .rigButton {
    all: initial;
  }
}
.ctc .rigselection p {
  font-size: 12px;
}

.ctc button .rigInfo {
  display: none;
}
.ctc .container.selectionPage {
  flex-direction: row;
  padding: 5px;
  padding-top: 0;
}

.ctc .pedalDiv {
  flex-direction: row;
}

.ctc .pedalDiv {
  margin: 0;
  height: unset;
}

.ctc .pedalDiv > div {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.ctc .pedalDivImg {
  height: unset;
  object-fit: unset;
}

.ctc .pedalDivImg img {
  width: 100px;
  height: auto;
  object-fit: unset;
}

.ctc .pedalSelection {
  margin: 0;
  align-items: start;
}

.ctc .phraseDiv {
  margin: 0;
}
.ctc .phraseDiv button {
  width: 100%;
}

.ctc .phraseselection {
  width: unset;
  padding: 0;
  height: unset;
  flex-direction: column;
  gap: 0;
}

.ctc .phraseselection p {
  margin: 0;
  font-size: 12px;
}

.phraseselection span {
  display: none;
}

.ctc .phraseselection span {
  display: inline;
}

.ctc .timeline {
  margin: 0;
  height: unset;
  width: unset;
}

.ctc .timelineBtnContent img {
  width: 18px;
  height: 18px;
}
.ctc .timeline button {
  all: unset;
  background: radial-gradient(50% 98.88% at 50% 50%, #474747 0%, #3c3c3c 100%);
  border: 2px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.75);
  border-radius: 40px;
  padding: 12px;
}
.ctc .pedalSelection {
  flex-direction: row;
}

.ctc .selectPhrase {
  width: 85% !important;
}

.ctc .rigs {
  margin-bottom: 15px;
  gap: 15px;
}

.ctc .mappedPedals {
  padding: 0 1rem;
  gap: 15px;
  margin: 10px 0;
}

.ctc .singlePedal.singleRig {
  width: unset;
  margin: 0;
  padding: 5px;
  flex-basis: calc(50% - 10px);
  gap: 0;
}

.ctc .singlePedal.singleRig img {
  width: unset !important;
  max-width: 115px;
  height: auto !important;
}

.ctc .singlePedal.singleRig p {
  padding-top: 0;
}

.ctc .modalBg {
  width: 100vw;
  height: 100dvh;
}

.ctc .modalHeading {
  position: relative;
  width: 100%;
  justify-content: end;
}

.ctc .phraseModal {
  padding: 0;
  width: 100vw;
  height: 100dvh;
  max-height: unset;
  padding: 0;
  gap: 0;
}

.ctc .headingPTag {
  display: flex;
  flex-direction: column;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ctc .closeModal {
  margin-right: 26px;
  font-size: 26px !important;
  display: flex;
  align-items: center;
}

.ctc .phraseDiv .headingPTag {
  font-size: 12px;
  padding: 10px 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ctc .phraseDiv .closeModal {
  font-size: 26px !important;
  padding: 10px 0;
}

.ctc .phraseModalPedal {
  width: 100vw;
  height: 100dvh;
  padding: 0;
  gap: 0;
}

.ctc .singlePedal.singlePhrase {
  padding: 5px 0;
  margin: 0;
  width: unset;
}

.ctc .singlePedal.singlePhrase img {
  width: 101px !important;
  height: auto !important;
}

.ctc .vsText {
  color: white;
  margin: auto 0;
  font-weight: bold;
}

@media screen and (max-width: 450px) {
  .ctc .container.selectionPage {
    flex-direction: column;
  }

  .ctc .rigs {
    flex-direction: row;
  }
  .ctc .clickableImages img {
    color: red;
  }
  .ctc .clickableImages {
    width: 100%;
    height: 100%;
  }
  .ctc .rigselection {
    flex-basis: calc(50% - 7.5px);
  }
  .ctc .rigButton {
    width: unset;
    margin: auto !important;
    padding: 6px;
  }
  .ctc .selectPhrase {
    width: 100% !important;
  }
  .ctc-logo {
    margin-bottom: 15px;
  }
  .ctc .selectPhrase {
    margin-bottom: 15px;
  }
  .ctc .rigselection p {
    height: unset;
    min-height: 20px;
  }
  .ctc .singlePedal {
    justify-content: space-between;
  }
  .ctc .modalHeading {
    position: sticky;
    top: 0;
  }
}
