.navbar {
  width: 100%;
  height: 64px;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  z-index: 100000 !important;
}
.navBack {
  background: #000000;
  mix-blend-mode: overlay;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 64px;
  left: 0px;
  top: 0px;
}
.navContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px 10px;
  z-index: 10;
}
.navHeading {
  white-space: nowrap;
}
.navHeading,
.navHeadingMob {
  justify-content: start;
  margin-left: 10px;
  display: flex;
  flex-grow: 6;
  text-decoration: none;
}
.navHeading > h1,
.navHeadingMob > h1 {
  color: #19e4f1;
  text-decoration: none !important;
}
.navHeadingMob > h1 {
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.navLink {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;
  width: 140px;
  height: 36px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin: 10px 10px;
  border: none;
  text-decoration: none;
}
.navLink p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.burgerIcon {
  color: #19e4f1;
  display: none;
  z-index: 1000;
}
.navMobContent {
  display: none;
}
.navHeadingMob {
  display: none;
}
@media screen and (max-width: 1024px) {
  .burgerIcon {
    display: block;
    width: 32px;
    height: 32px;
    margin: auto 15px;
  }
  .navbar {
    position: sticky;
  }
  .navHeadingMob {
    backdrop-filter: blur(4px);
    display: flex;
    z-index: 1;
    width: 80%;
  }
  .navContent {
    width: 100%;
    display: none;
  }
  .navMobContent {
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 60px;
    background-color: #000000;
    height: fit-content;
    width: 100%;
    margin-top: 5px;
  }
  .navMobContent a {
    display: flex;
    justify-content: center;
    margin: 10px auto;
  }
}
@media screen and (max-width: 550px) {
  .burgerIcon {
    display: block;
    width: 32px;
    height: 32px;
    margin: auto 15px;
  }
  .navbar {
    position: sticky;
  }
  .navHeadingMob {
    display: flex;
    z-index: 1;
    width: 80%;
  }
  .navContent {
    width: 100%;
    display: none;
  }
  .navMobContent {
    display: flex;
    position: fixed;
    flex-direction: column;
    top: 60px;
    background-color: #000000;
    height: fit-content;
    width: 100%;
    margin-top: 5px;
  }
  .navMobContent a {
    display: flex;
    justify-content: center;
    margin: 10px auto;
  }
}
