h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.contactPage {
    z-index: 10;
    height: calc(100vh - 64px) !important;
}
.formElement {
    width: 50%;
    margin: 0%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: start;
}
.formElement label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}
.formElement input,
.formElement textarea {
    width: 450px;
    border-radius: 7px;
    border: none;
    box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.45), 6px 6px 12px rgba(0, 0, 0, 0.45);
    padding: 10px;
}
.formElement input:focus,
.formElement textarea:focus {
    outline: none;
}
.contactPage button {
    padding: 8px 32px;
    gap: 4px;
    width: 140px;
    height: 52px;
    background: #D6F119;
    border-radius: 48px;
    border: none;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.45);
    margin-top: 15px;
}
.contactPage button > p {
    color: #1A1A1A;
    font-size: 20px;
    margin: 0%;
}
.contactPage button:hover {
    background-color: #d4f119b9;
    cursor: pointer;
}
@media screen and (max-width: 1400px) {
    .contactPage {
        height: 100vh;
        overflow-y: hidden !important;
    }
}
@media screen and (max-width: 550px) {
    .contactPage {
        width: 100%;
    }
    .contactPage form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        flex-direction: column;
    }
    .formElement {
        width: 100%;
    }
    .formElement input,
    .formElement textarea {
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
    .formElement label {
        display: flex;
        margin: 0 auto;
        align-items: center;
    }
}